<template>
  <div class="settings">
    <ValidationObserver ref="form">
      <form
        id="kt_form"
        novalidate
        class="form"
        @submit.prevent="onSubmit"
      >
        <b-card v-if='!isDataLoaded'>
          <div class='text-center text-success my-2'>
            <b-spinner class='align-middle' />
          </div>
        </b-card>

        <b-card  v-else>
          <b-tabs>
            <b-tab
              v-for="(value, propertyName,index) in form"
              :key="index+propertyName"
              no-body
              :title="$t(`settings_t.${propertyName}`)"
            >
              <div class="row justify-content-center">
                <b-col
                  cols="12"
                >
                  <div
                    v-for="(item,name,i) in value"
                    :key="i+name"
                  >
                    <ValidationProvider
                      v-if="item.data_type==='text' || item.data_type==='float' || item.data_type==='image' || item.data_type==='text' || item.data_type==='date'"
                      v-slot="{ errors }"
                      :vid="item.key"
                      :name="item.key"
                      :rules="item.is_required ?'required': ''"
                    >
                      <b-form-group
                        :label="filterText(name)"
                      >
                        <b-form-input
                          v-if="(item.data_type==='text' || item.data_type==='float')"
                          v-model="item.value"
                          class="form-control form-control-solid"
                          :type="item.data_type==='float'?'number':'text'"
                          :placeholder="filterText(item.key)"
                        />
                        <ImageUpload
                          v-if="item.data_type==='image'"
                          v-model="item.value"
                          :label="''"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </ValidationProvider>

                    <ValidationProvider
                      v-if="item.data_type==='textarea'"
                      v-slot="{ errors }"
                      :vid="item.key"
                      :name="item.key"
                      :rules="item.is_required ?'required': ''"
                    >
                      <b-form-group
                        :label="filterText(name)"
                      >
                        <b-form-textarea
                          v-if="item.data_type==='textarea'"
                          v-model="item.value"
                          class="form-control form-control-solid"
                          :type="item.data_type==='float'?'number':'text'"
                          :placeholder="filterText(item.key)"
                          rows="3"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </ValidationProvider>

                    <div v-if="item.data_type==='json' || item.data_type === 'translatable'">
                      <LocaleTabs
                        v-if="item.key !== 'thankyou_mail_message'"
                        name="title"
                      >
                        <template
                          v-for="(i,k,idx) in languages"
                          :slot="`lang${idx}`"
                          slot-scope="slotScope"
                        >
                          <ValidationProvider
                            :key="idx"
                            v-slot="{ errors }"
                            :class="slotScope.selectedTab!==idx?'hidden':''"
                            :vid="item.key[k]"
                            :name="`${i}`"
                            :rules="item.is_required ? 'required':''"
                          >
                            <b-form-group :label="`${filterText(item.key)}`">
                              <b-form-input
                                v-model="item.origin_value[k]"
                                :placeholder="`${i}`"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </b-form-group>
                          </ValidationProvider>
                        </template>
                      </LocaleTabs>
                    </div>

                    <div v-if="item.data_type === 'array'">
                      <p class="text-capitalize">
                        {{ filterText(item.key) }}
                      </p>
                      <b-row
                        v-for="(the_item,the_index) in item.value"
                        :key="the_index"
                      >
                        <div class="col-12">
                          <LocaleTabs
                            :name="the_item.title"
                          >
                            <template
                              v-for="(lang_item,lang_k,lang_idx) in the_item.title"
                              :slot="`lang${lang_idx}`"
                              slot-scope="slotScope"
                            >
                              <ValidationProvider
                                :key="lang_idx"
                                v-slot="{ errors }"
                                :class="slotScope.selectedTab!==lang_idx?'hidden':''"
                                :vid="`${lang_k}`"
                                :name="`Title`"
                                :rules="the_item.is_required ? 'required':''"
                              >
                                <b-form-group :label="`Title`">
                                  <b-form-input
                                    v-model="the_item.title[lang_k]"
                                    :placeholder="`${lang_item}`"
                                  />
                                  <small class="text-danger">{{ errors[0] }}</small>
                                </b-form-group>
                              </ValidationProvider>
                            </template>
                          </LocaleTabs>
                        </div>
                        <div
                          class="col-12"
                        >
                          <LocaleTabs
                            :name="the_item.description"
                          >
                            <template
                              v-for="(lang_item,lang_k,lang_idx) in the_item.description"
                              :slot="`lang${lang_idx}`"
                              slot-scope="slotScope"
                            >
                              <ValidationProvider
                                :key="lang_idx"
                                v-slot="{ errors }"
                                :class="slotScope.selectedTab!==lang_idx?'hidden':''"
                                :vid="`${lang_k}`"
                                :name="`Description`"
                                :rules="the_item.is_required ? 'required':''"
                              >
                                <b-form-group :label="`Description`">
                                  <b-form-textarea
                                    v-model="the_item.description[lang_k]"
                                    :placeholder="`${lang_item}`"
                                  />
                                  <small class="text-danger">{{ errors[0] }}</small>
                                </b-form-group>
                              </ValidationProvider>
                            </template>
                          </LocaleTabs>
                        </div>
                        <div class="col-12">
                          <ImageUpload
                            v-model="the_item.icon"
                            :label="''"
                          />
                          <hr class="my-4">
                        </div>
                      </b-row>
                    </div>

                    <b-form-group
                      v-if="item.data_type==='boolean'"
                      :label="filterText(item.key)"
                    >
                      <span class="switch switch-icon">
                        <label>
                          <input
                            v-model="item.value"
                            type="checkbox"
                            true-value="true"
                            false-value="false"
                          >
                          <span />
                        </label>
                      </span>
                    </b-form-group>
                  </div>
                </b-col>
              </div>
            </b-tab>
          </b-tabs>
          <b-row>
            <!-- submit and reset -->
            <b-col cols="12 text-right">
              <LoadingButton :is-disabled="buttonDisabledStatus" />
            </b-col>
          </b-row>
        </b-card>
      </form>
      .
    </ValidationObserver>
  </div>
</template>

<script>
import formMixin from '@/mixins/formMixin'

export default {
  mixins: [formMixin],
  data() {
    return {
      buttonDisabledStatus: true,
      form: null,
      images: [],
      keys: ['section_1_image', 'section_2_image', 'site_image', 'site_logo', 'site_logo_header', 'site_logo_footer', 'favicon', 'qr', 'contactus_section_1_image'],
      isDataLoaded: false,
    }
  },
  computed: {
    languages() {
      return {
        en: 'English',
        ar: 'Arabic',
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.$watch('form', () => {
        this.buttonDisabledStatus = false
      }, {
        immediate: false,
        deep: true,
      })
    }, 1000)
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.axios.get('/settings')
        .then(res => {
          this.form = res.data.data
          Object.entries(this.form)
            .forEach(([key, value]) => {
              Object.entries(value)
                .forEach(([k, v]) => {
                  if (this.keys.includes(k)) {
                    this.images.push(v.value)
                  }
                })
            })
          this.isDataLoaded = true
        })
    },
    loadFormData() {
      let $fields = _.cloneDeep(this.form)
      $fields = {
        ...$fields.about,
        ...$fields.conditions_policies,
        ...$fields.contact_us,
        ...$fields.general,
        ...$fields.maps,
        ...$fields.newsletter,
        ...$fields.services,
        ...$fields.site,
        ...$fields.social_media,
        ...$fields.statistics,
        ...$fields.real_estate,
        ...$fields.steps,
        ...$fields.values,
        ...$fields.store,
        ...$fields.real_estate_map,
        ...$fields.real_estate_payment,

      }
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries($fields)) {
        if (this.keys.includes(key)) {
          if (this.images.includes(value.value)) {
            $fields[key] = ''
          } else {
            $fields[key] = value.value
          }
        } else if (value.data_type === 'translatable') {
          $fields[key] = value.origin_value
        } else {
          $fields[key] = value.value
        }
      }
      return $fields
    },
    filterText(text) {
      return this.$t(`${text}`)
    },
  },
}
</script>

<style lang="scss">
.settings .nav .nav-link {
  text-transform: capitalize;
}
</style>
